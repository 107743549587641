<template lang="">
  <AppCollapse type="card">
    <AppCollapseItem
      title
      isVisible
    >
      <template #header>
        <div :class="`text-tour fw-700 ${isMobileView ? 'text-16px' : 'text-24px'}`">
          Lịch khởi hành
        </div>
      </template>
      <!-- ANCHOR select month -->
      <IAmOverlay :loading="loadingCalendar || loadingFetchCalendarData">
        <Stack class="gap-2">
          <HStack
            center
            :class="`${isMobileView ? '' : 'gap-3'}`"
          >
            <BButton
              v-for="(monthItem, monthIndex) in monthOptions"
              :key="monthIndex"
              :variant="monthItem.month === currentMonth && monthItem.year === currentYear ? 'success' : 'light'"
              :class="`flex-1 p-75 border-secondary rounded-lg text-center ${monthItem.month === currentMonth && monthItem.year === currentYear ? 'text-white' : 'text-dark'} fw-700 ${isMobileView ? '' : 'text-16px'}`"
              @click="currentMonth = monthItem.month; currentYear = monthItem.year; "
            >
              <components
                :is="isMobileView ? 'Stack' : 'HStack'"
                center
              >
                <div class="text-nowrap">
                  Tháng {{ monthItem.month }},
                </div>
                <div>{{ monthItem.year }}</div>
              </components>
            </BButton>
          </HStack>
          <!-- ANCHOR calendar -->
          <div class="d-flex-between gap-2">
            <span
              v-for="day in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
              :key="day"
              :class="`flex-1 text-center text-success fw-700 ${isMobileView ? '' : 'text-20px'}`"
            >{{ $t(`${isMobileView ? 'short' : ''}${day}`) }}</span>
          </div>
          <div v-if="weeks.length">
            <div
              v-for="(week, weekIndex) in weeks"
              :key="weekIndex"
              :class="`d-flex-between ${ isMobileView ? 'gap-1' : 'gap-2' }`"
            >
              <div
                v-for="(day, dayIndex) in week"
                :key="dayIndex"
                class="day flex-1 rounded-lg my-25 d-flex-center"
                :class="day?.isCurrent
                  ? 'bg-light-danger border-danger-2 current-day'
                  : day?.minPrice
                    ? 'bg-light border-dark have-price-day'
                    : day?.minPrice === 0
                      ? 'border-secondary bg-gray'
                      : 'bg-gray'"
                style="height: 80px"
                @click="onHandleClickDay(day)"
              >
                <div v-if="day">
                  <div :class="`d-flex-center  ${isMobileView ? 'flex-column gap-1' : 'flex-row gap-2 align-items-end'}`">
                    <span :class="`fw-700 ${isMobileView ? 'text-14px' : 'text-18px'} text-dark`">
                      {{ padNumber(new Date(day.date).getDate()) }}
                    </span>

                    <span :class="`font-weight-light ${day.lunarDate.date === 1 ? 'text-danger' : 'text-secondary'} ${isMobileView ? 'font-small-1' : 'text-14px'}`">
                      <span>{{ padNumber(day.lunarDate.date) }}</span>
                      <span v-if="day.lunarDate.date === 1">/{{ day.lunarDate.month }}</span>
                      <span v-if="day.lunarDate.isLeap">(N)</span>
                    </span>
                  </div>

                  <div
                    v-if="day.minPrice"
                    :class="`fw-700 ${isMobileView ? 'text-12px' : 'text-20px'} text-danger`"
                  >
                    {{ formatCurrency(isMobileView ? (day?.minPrice / 1000) : day?.minPrice) }}<span v-if="isMobileView">k</span>
                  </div>

                  <div
                    v-else-if="day.minPrice === 0"
                    :class="`fw-700 text-danger text-nowrap ${isMobileView ? 'text-12px' : 'text-20px'}`"
                  >
                    Hết chỗ
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BAlert
            v-else
            show
            variant="warning"
            class="px-2 py-1"
            rounded-16px
          >
            Đang tải dữ liệu...
          </BAlert>
        </Stack>
      </IAmOverlay>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { BAlert, BButton } from 'bootstrap-vue'
import {
  computed, ref, watch,
} from '@vue/composition-api'
import { minBy, isNil } from 'lodash-es'
import moment from 'moment'

import { formatCurrency, getArrayDatesBetween, padNumber } from '@/@core/utils/filter'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import router from '@/router'

import { useTourCalendarHandle } from './useTourDetailHandle'

export default {
  components: {
    BButton,
    BAlert,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    tourSelected: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getTourCalendar, loadingCalendar } = useTourCalendarHandle()

    const tourStartDateMonth = ref((new Date(props.tourSelected.startDate)).getMonth() + 1)
    const tourStartDateYear = ref((new Date(props.tourSelected.startDate)).getFullYear())

    const NUMBER_OF_MONTHS = 4
    const monthOptions = ref(Array.from(Array(NUMBER_OF_MONTHS), (_, i) => {
      let year = tourStartDateYear.value
      let month = tourStartDateMonth.value + i
      if (month > 12) {
        month -= 12
        year += 1
      }
      return { month, year }
    }))
    const currentMonth = ref(tourStartDateMonth.value) // tháng đang chọn
    const currentYear = ref(tourStartDateYear.value) // năm đang chọn
    const calendarData = ref([]) // lưu giá
    const calendarArray = ref([]) // lưu ngày
    const SIZE_CALENDAR_FETCH_PER_TIME = 10

    const weeks = computed(() => {
      if (!calendarArray.value.length) return []
      const weeks = []
      let week = Array(7).fill(null)

      calendarArray.value.forEach(item => {
        week[item.dayOfWeek] = item
        const findDate = calendarData.value.find(data => data.startDate.includes(item.date))
        if (findDate) {
          const minPrice = minBy(
            findDate?.prices || [],
            'adultsPromotion',
          ) ?? null
          if (!isNil(minPrice)) {
            week[item.dayOfWeek].minPrice = minPrice?.adultsPromotion
            week[item.dayOfWeek].isCurrent = props.tourSelected.startDate.includes(item.date)
            week[item.dayOfWeek].priceId = minPrice?.id
          }
        }

        if (item.dayOfWeek === 6) {
          weeks.push([...week])
          week = Array(7).fill(null)
        }
      })

      if (week.some(day => day !== null)) {
        weeks.push(week)
      }

      return weeks
    })

    async function getTourCalendarData(page = 1, tourId = props.tourSelected.tourId) {
      if (tourId) {
        try {
          const params = {
          // startDate: props.tourSelected.startDateText,
            size: SIZE_CALENDAR_FETCH_PER_TIME,
            page,
          }
          const res = await getTourCalendar(tourId, params)
          return res
        } catch (error) {
          console.error({ error })
        }
      }
      return null
    }

    const calendarListResult = ref([])
    const preTourId = ref()

    const loadingFetchCalendarData = ref(false)
    async function fetchCalendarData(month, year, tourId) {
      loadingFetchCalendarData.value = true
      if (!calendarData.value.length
        || moment(calendarListResult.value[calendarListResult.value.length - 1]?.startDate)
          .isSameOrBefore(moment(new Date(year, month - 1, 31)))
      ) {
        let page

        if (preTourId.value !== tourId) {
          preTourId.value = tourId
          calendarListResult.value = []
          page = 1
        }
        else {
          page = Math.ceil(calendarListResult.value.length / SIZE_CALENDAR_FETCH_PER_TIME) + 1
        }

        do {
          const res = await getTourCalendarData(page, tourId)
          if (!res.length) break
          calendarListResult.value.push(...res)
          page += 1
        } while (!calendarListResult.value.length || moment(calendarListResult.value[calendarListResult.value.length - 1]?.startDate).isSameOrBefore(moment(new Date(year, month - 1, 31))))
      }

      calendarData.value = calendarListResult.value
        .filter(calendarItem => moment(calendarItem?.startDate).isSameOrBefore(moment(new Date(year, month - 1, 31)))
          && moment(calendarItem?.startDate).isSameOrAfter(moment(new Date(year, month - 1, 1))))
      loadingFetchCalendarData.value = false
    }

    function initCalendarDate() {
      calendarArray.value = getArrayDatesBetween(
        `${currentYear.value}-${padNumber(currentMonth.value)}-01`,
        undefined,
        {
          includeDayOfWeek: true,
          includeLunarDate: true,
        },
      )
    }

    watch([currentMonth, currentYear], () => {
      if (currentMonth.value && currentYear.value) {
        initCalendarDate()
        fetchCalendarData(currentMonth.value, currentYear.value, props.tourSelected.tourId)
      }
    }, { immediate: true })

    function onHandleClickDay(day) {
      if (day.priceId && !day.isCurrent && day.minPrice) {
        router.push({ name: 'apps-toursDetail', params: { id: day.priceId } })
      }
    }

    return {
      calendarData,
      currentMonth,
      currentYear,
      tourStartDateMonth,
      tourStartDateYear,
      monthOptions,
      weeks,
      padNumber,
      loadingCalendar,
      formatCurrency,
      onHandleClickDay,
      isNil,
      loadingFetchCalendarData,
    }
  },
}
</script>

<style lang="scss" scoped>
.day {
  cursor: not-allowed;
  &.have-price-day:hover {
    cursor: pointer;
    background-color: #cccccc50 !important;
  }
  &.current-day:hover {
    cursor: default;
    background-color: #EF535050 !important;
  }
}
</style>
