<template lang="">
  <AppCollapse type="card">
    <AppCollapseItem title>
      <template #header>
        <div :class="`text-tour ${isMobileView ? 'text-16px' : 'text-24px'} fw-700`">
          Lịch trình cụ thể
        </div>
      </template>
      <div v-if="contentTour?.length">
        <Stack
          v-for="(content, contentIndex) in contentTour"
          :key="contentIndex"
        >
          <Stack
            center
            class="gap-2"
          >
            <div class="text-20px fw-700">
              {{ content?.title }}
            </div>
            <div
              class="w-100"
              v-html="modifyDescription(content?.description)"
            />
          </Stack>
        </Stack>
      </div>
      <div v-else>
        <span class="font-italic">Không có thông tin</span>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>

import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: { AppCollapse, AppCollapseItem },
  props: {
    contentTour: {
      type: Array,
      default: () => ([]),
    },
  },
  setup() {
    function modifyDescription(description) {
      return description.replaceAll('<img', '<img width="100%" height="auto"')
    }
    return { modifyDescription }
  },
}
</script>
<style lang="">

</style>
