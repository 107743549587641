var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BButton', {
    class: "".concat(_vm.isMobileView ? 'px-1 py-50' : 'px-2 py-75', " rounded-8px"),
    attrs: {
      "id": "popover-download-info",
      "variant": "success"
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "download",
      "size": "18"
    }
  }), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v("Tải thông tin về")])], 1)], 1), _c('BPopover', {
    staticClass: "unset-m-width",
    attrs: {
      "target": "popover-download-info",
      "custom-class": "fit-content unset-m-width",
      "variant": "success",
      "triggers": ['click', 'focus']
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between"
        }, [_c('div', [_vm._v("Chọn file để xem")]), _c('BButton', {
          staticClass: "p-25 rounded-circle",
          attrs: {
            "variant": "flat-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.$root.$emit('bv::hide::popover', 'popover-download-info');
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "closeOutline",
            "size": "18"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('Stack', _vm._l(_vm.sources, function (source, sourceIndex) {
    return _c('div', {
      key: sourceIndex
    }, [_c('BLink', {
      class: "".concat(_vm.isMobileView ? 'text-wrap' : 'text-nowrap', " fit-content unset-m-width hover-link p-50 rounded fw-700 text-truncate"),
      attrs: {
        "href": source.pathExt,
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(source.name) + " ")])], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }