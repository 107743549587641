var render = function () {
  var _vm$tourSelected, _vm$tourSelected2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "rounded-8px mb-0 position-relative",
    attrs: {
      "body-class": "px-1 d-flex-between flex-column align-items-stretch gap-2"
    }
  }, [_c('div', {}, [_c('div', [_vm._v("Giá chỉ từ:")]), _c('div', {
    staticClass: "text-danger fw-900 d-flex-center justify-content-start gap-1 font-large-1 flex-wrap"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected = _vm.tourSelected) === null || _vm$tourSelected === void 0 ? void 0 : _vm$tourSelected.adultsPriceSales)) + "VND "), _c('span', {
    staticClass: "text-dark fw-500 text-18px"
  }, [_vm._v("/ khách")])])]), _c('TourDetailInfo', {
    attrs: {
      "tourSelected": _vm.tourSelected,
      "customClass": "d-flex-between flex-column align-items-stretch gap-2"
    }
  }), _c('BButton', {
    class: "w-100 rounded-lg text-18px fw-700 py-50 ".concat(_vm.isMobileView ? 'sticky-btn' : ''),
    attrs: {
      "variant": "success",
      "disabled": !((_vm$tourSelected2 = _vm.tourSelected) !== null && _vm$tourSelected2 !== void 0 && _vm$tourSelected2.numberTicketsAvailable),
      "to": {
        name: 'apps-toursBook'
      }
    }
  }, [_vm._v(" Đặt Tour ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }