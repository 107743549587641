<template lang="">
  <IAmOverlay :loading="loadingDetail">
    <div
      v-if="tourSelected && keyTour"
      :key="keyTour"
      class="d-flex flex-column gap-3 my-1"
      :class="{ 'mx-1': isMobileView }"
    >
      <TourDetailBreadcrumbs :paths="paths" />
      <TourDetailImages :image="tourSelected?.image?.path || tourSelected?.block?.image?.path || backgroundTourUrl" />
      <BRow class="mb-1">
        <BCol
          cols="12"
          md="9"
          class="d-flex flex-column gap-3"
        >
          <Stack class="gap-3">
            <TourDetailScheduleAndPrice :tourSelected="tourSelected" />
            <TourDetailInfoCard
              v-if="isMobileView"
              :tourSelected="tourSelected"
            />
            <TourDetailDownloadInfoButton
              v-if="tourSelected?.fileUpload?.length"
              :sources="tourSelected?.fileUpload"
            />
            <TourDetailDepartCalendar :tourSelected="tourSelected" />
            <TourDetailInfoFlightSchedule :contentTour="tourSelected?.contentTour ?? []" />
            <TourDetailInfoNote :contentConditions="tourSelected?.contentConditions ?? []" />
            <TourDetailAnotherTours :anotherTours="tourSelected?.anotherTours ?? []" />
          </Stack>
        </BCol>
        <BCol
          v-if="!isMobileView"
          cols="12"
          md="3"
          class="pl-md-0"
        >
          <Stack class="right-side-sticky gap-3">
            <TourDetailInfoCard :tourSelected="tourSelected" />

            <TourListDisplaySettings />
          </Stack>
        </BCol>
      </BRow>
    </div>
    <BAlert
      v-else-if="loadingDetail"
      show
      variant="warning"
      class="px-2 py-1"
    >
      Đang tải dữ liệu ...
    </BAlert>
    <BAlert
      v-else
      show
      variant="danger"
      class="px-2 py-1"
    >
      Không tìm thấy tour đã chọn. Vui lòng chọn lại tour!
    </BAlert>
  </IAmOverlay>
</template>
<script>
import {
  BAlert, BCol, BRow,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import env from '@/libs/env'

import TourDetailImages from './TourDetailImages.vue'
import TourDetailDepartCalendar from './TourDetailDepartCalendar.vue'
import TourDetailScheduleAndPrice from '../components/TourDetailScheduleAndPrice.vue'
import TourDetailInfoCard from './TourDetailInfoCard.vue'
import TourDetailInfoFlightSchedule from './TourDetailInfoFlightSchedule.vue'
import TourDetailInfoNote from './TourDetailInfoNote.vue'
import useTourDetailHandle from './useTourDetailHandle'
import TourDetailBreadcrumbs from '../components/TourDetailBreadcrumbs.vue'
import TourDetailDownloadInfoButton from './TourDetailDownloadInfoButton.vue'
import TourDetailAnotherTours from './TourDetailAnotherTours.vue'
import TourListDisplaySettings from '../components/TourListDisplaySettings.vue'

export default {
  components: {
    TourDetailImages,
    TourDetailScheduleAndPrice,
    TourDetailInfoCard,
    TourDetailDepartCalendar,
    TourDetailInfoFlightSchedule,
    TourDetailInfoNote,
    TourDetailBreadcrumbs,
    TourDetailDownloadInfoButton,
    TourDetailAnotherTours,
    TourListDisplaySettings,
    BRow,
    BCol,
    BAlert,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup(props, { root }) {
    const keyTour = ref(uuidv4())
    const { tourSelected, loadingDetail, getTourRowDetail } = useTourDetailHandle()
    const paths = computed(() => [
      {
        name: 'Tour Du Lịch',
        to: { name: 'apps-toursList' },
      },
      {
        name: tourSelected.value?.name,
        current: true,
      },
    ])

    watch(() => [root.$route, tourSelected], async ([newRoute]) => {
      if (newRoute.params?.id) {
        await getTourRowDetail(newRoute.params?.id)
        keyTour.value = uuidv4()
      }
    })

    return {
      tourSelected,
      loadingDetail,
      paths,
      backgroundTourUrl: env.backgroundTourUrl,
      keyTour,
    }
  },
}
</script>
<style lang="scss">

.right-side-sticky {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 55px;
  right: 0;
  max-height: calc(100vh - 55px);
}
</style>
