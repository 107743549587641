var render = function () {
  var _vm$tourSelected, _vm$tourSelected$imag, _vm$tourSelected2, _vm$tourSelected2$blo, _vm$tourSelected2$blo2, _vm$tourSelected3, _vm$tourSelected3$fil, _vm$tourSelected4, _vm$tourSelected$cont, _vm$tourSelected5, _vm$tourSelected$cont2, _vm$tourSelected6, _vm$tourSelected$anot, _vm$tourSelected7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingDetail
    }
  }, [_vm.tourSelected && _vm.keyTour ? _c('div', {
    key: _vm.keyTour,
    staticClass: "d-flex flex-column gap-3 my-1",
    class: {
      'mx-1': _vm.isMobileView
    }
  }, [_c('TourDetailBreadcrumbs', {
    attrs: {
      "paths": _vm.paths
    }
  }), _c('TourDetailImages', {
    attrs: {
      "image": ((_vm$tourSelected = _vm.tourSelected) === null || _vm$tourSelected === void 0 ? void 0 : (_vm$tourSelected$imag = _vm$tourSelected.image) === null || _vm$tourSelected$imag === void 0 ? void 0 : _vm$tourSelected$imag.path) || ((_vm$tourSelected2 = _vm.tourSelected) === null || _vm$tourSelected2 === void 0 ? void 0 : (_vm$tourSelected2$blo = _vm$tourSelected2.block) === null || _vm$tourSelected2$blo === void 0 ? void 0 : (_vm$tourSelected2$blo2 = _vm$tourSelected2$blo.image) === null || _vm$tourSelected2$blo2 === void 0 ? void 0 : _vm$tourSelected2$blo2.path) || _vm.backgroundTourUrl
    }
  }), _c('BRow', {
    staticClass: "mb-1"
  }, [_c('BCol', {
    staticClass: "d-flex flex-column gap-3",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('Stack', {
    staticClass: "gap-3"
  }, [_c('TourDetailScheduleAndPrice', {
    attrs: {
      "tourSelected": _vm.tourSelected
    }
  }), _vm.isMobileView ? _c('TourDetailInfoCard', {
    attrs: {
      "tourSelected": _vm.tourSelected
    }
  }) : _vm._e(), (_vm$tourSelected3 = _vm.tourSelected) !== null && _vm$tourSelected3 !== void 0 && (_vm$tourSelected3$fil = _vm$tourSelected3.fileUpload) !== null && _vm$tourSelected3$fil !== void 0 && _vm$tourSelected3$fil.length ? _c('TourDetailDownloadInfoButton', {
    attrs: {
      "sources": (_vm$tourSelected4 = _vm.tourSelected) === null || _vm$tourSelected4 === void 0 ? void 0 : _vm$tourSelected4.fileUpload
    }
  }) : _vm._e(), _c('TourDetailDepartCalendar', {
    attrs: {
      "tourSelected": _vm.tourSelected
    }
  }), _c('TourDetailInfoFlightSchedule', {
    attrs: {
      "contentTour": (_vm$tourSelected$cont = (_vm$tourSelected5 = _vm.tourSelected) === null || _vm$tourSelected5 === void 0 ? void 0 : _vm$tourSelected5.contentTour) !== null && _vm$tourSelected$cont !== void 0 ? _vm$tourSelected$cont : []
    }
  }), _c('TourDetailInfoNote', {
    attrs: {
      "contentConditions": (_vm$tourSelected$cont2 = (_vm$tourSelected6 = _vm.tourSelected) === null || _vm$tourSelected6 === void 0 ? void 0 : _vm$tourSelected6.contentConditions) !== null && _vm$tourSelected$cont2 !== void 0 ? _vm$tourSelected$cont2 : []
    }
  }), _c('TourDetailAnotherTours', {
    attrs: {
      "anotherTours": (_vm$tourSelected$anot = (_vm$tourSelected7 = _vm.tourSelected) === null || _vm$tourSelected7 === void 0 ? void 0 : _vm$tourSelected7.anotherTours) !== null && _vm$tourSelected$anot !== void 0 ? _vm$tourSelected$anot : []
    }
  })], 1)], 1), !_vm.isMobileView ? _c('BCol', {
    staticClass: "pl-md-0",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('Stack', {
    staticClass: "right-side-sticky gap-3"
  }, [_c('TourDetailInfoCard', {
    attrs: {
      "tourSelected": _vm.tourSelected
    }
  }), _c('TourListDisplaySettings')], 1)], 1) : _vm._e()], 1)], 1) : _vm.loadingDetail ? _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Đang tải dữ liệu ... ")]) : _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Không tìm thấy tour đã chọn. Vui lòng chọn lại tour! ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }