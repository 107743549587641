var render = function () {
  var _vm$contentTour;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    attrs: {
      "type": "card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          class: "text-tour ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px', " fw-700")
        }, [_vm._v(" Lịch trình cụ thể ")])];
      },
      proxy: true
    }])
  }, [(_vm$contentTour = _vm.contentTour) !== null && _vm$contentTour !== void 0 && _vm$contentTour.length ? _c('div', _vm._l(_vm.contentTour, function (content, contentIndex) {
    return _c('Stack', {
      key: contentIndex
    }, [_c('Stack', {
      staticClass: "gap-2",
      attrs: {
        "center": ""
      }
    }, [_c('div', {
      staticClass: "text-20px fw-700"
    }, [_vm._v(" " + _vm._s(content === null || content === void 0 ? void 0 : content.title) + " ")]), _c('div', {
      staticClass: "w-100",
      domProps: {
        "innerHTML": _vm._s(_vm.modifyDescription(content === null || content === void 0 ? void 0 : content.description))
      }
    })])], 1);
  }), 1) : _c('div', [_c('span', {
    staticClass: "font-italic"
  }, [_vm._v("Không có thông tin")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }