<template lang="">
  <BCard
    class="rounded-8px mb-0 position-relative"
    body-class="px-1 d-flex-between flex-column align-items-stretch gap-2"
  >
    <div class="">
      <div>Giá chỉ từ:</div>
      <div class="text-danger fw-900 d-flex-center justify-content-start gap-1 font-large-1 flex-wrap">
        {{ formatCurrency(tourSelected?.adultsPriceSales) }}VND
        <span class="text-dark fw-500 text-18px">/ khách</span>
      </div>
    </div>

    <TourDetailInfo
      :tourSelected="tourSelected"
      customClass="d-flex-between flex-column align-items-stretch gap-2"
    />
    <BButton
      variant="success"
      :class="`w-100 rounded-lg text-18px fw-700 py-50 ${isMobileView ? 'sticky-btn' : ''}`"
      :disabled="!tourSelected?.numberTicketsAvailable"
      :to="{ name: 'apps-toursBook' }"
    >
      Đặt Tour
    </BButton>
  </BCard>
</template>
<script>
import { BButton, BCard } from 'bootstrap-vue'

import { formatCurrency } from '@/@core/utils/filter'

import { defaultDepartPoint } from '../useTourHandle'

export default {
  components: {
    BCard,
    BButton,
    TourDetailInfo: () => import('../components/TourDetailInfo.vue'),
  },
  props: {
    tourSelected: { type: Object, default: null },
  },
  setup() {
    return {
      formatCurrency,
      defaultDepartPoint,
    }
  },
}
</script>
<style lang="scss" scoped>
.sticky-btn {
  position: sticky;
  position: -webkit-sticky;
  bottom: 10px;
}
</style>
