var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center"
  }, [_c('BImg', {
    staticClass: "rounded-16px",
    style: "object-fit: contain; ".concat(_vm.isMobileView ? 'width: 100%' : 'max-height: 400px', ";"),
    attrs: {
      "src": _vm.image
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }