<template lang="">
  <div class="d-flex-center">
    <BImg
      :src="image"
      class="rounded-16px"
      :style="`object-fit: contain; ${isMobileView ? 'width: 100%' : 'max-height: 400px'};`"
    />
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    image: {
      type: String, default: '',
    },
  },
}
</script>
<style lang="">

</style>
