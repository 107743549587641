<template lang="">
  <div>
    <BButton
      id="popover-download-info"
      variant="success"
      :class="`${isMobileView ? 'px-1 py-50' : 'px-2 py-75'} rounded-8px`"
    >
      <HStack center>
        <IAmIcon
          icon="download"
          size="18"
        />
        <span class="fw-700">Tải thông tin về</span>
      </HStack>
    </BButton>

    <BPopover
      target="popover-download-info"
      custom-class="fit-content unset-m-width"
      class="unset-m-width"
      variant="success"
      :triggers="['click', 'focus']"
    >
      <template
        #title
        class="unset-m-width"
      >
        <div class="d-flex-between">
          <div>Chọn file để xem</div>
          <BButton
            variant="flat-danger"
            class="p-25 rounded-circle"
            @click="$root.$emit('bv::hide::popover', 'popover-download-info')"
          >
            <IAmIcon
              icon="closeOutline"
              size="18"
            />
          </BButton>
        </div>
      </template>
      <Stack>
        <div
          v-for="(source, sourceIndex) in sources"
          :key="sourceIndex"
        >
          <BLink
            :href="source.pathExt"
            target="_blank"
            :class="`${isMobileView ? 'text-wrap' : 'text-nowrap'} fit-content unset-m-width hover-link p-50 rounded fw-700 text-truncate`"
          >
            {{ source.name }}
          </BLink>
        </div>
      </Stack>
    </BPopover>
  </div>
</template>
<script>
import { BButton, BLink, BPopover } from 'bootstrap-vue'

export default {
  components: { BButton, BLink, BPopover },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style lang="scss" scoped>
.unset-m-width {
  max-width: unset;
}

@media screen and (max-width: 576px) {
  .unset-m-width {
    max-width: 80vw;
  }
}
.hover-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    background-color: #cccccc50;
  }
}
</style>
