<template lang="">
  <AppCollapse type="card">
    <AppCollapseItem title>
      <template #header>
        <div :class="`text-tour ${isMobileView ? 'text-16px' : 'text-24px'} fw-700`">
          Lưu ý
        </div>
      </template>
      <div v-if="contentConditions?.length">
        <Stack
          v-for="(content, contentIndex) in contentConditions"
          :key="contentIndex"
        >
          <Stack
            center
            class="gap-2"
          >
            <div class="text-20px fw-700">
              {{ content?.title }}
            </div>
            <div v-html="content?.description.replaceAll('Du lịch Thiên Nhiên', mamaFullName)" />
          </Stack>
        </Stack>
      </div>
      <div v-else>
        <span class="font-italic">Không có thông tin</span>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>

import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import env from '@/libs/env'

export default {
  components: { AppCollapse, AppCollapseItem },
  props: {
    contentConditions: {
      type: Array,
      default: () => ([]),
    },
  },
  setup() {
    return {
      mamaFullName: env.mamaFullName,
    }
  },
}
</script>
<style lang="">

</style>
